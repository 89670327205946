<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="$can('update', 'freefire-sg/my-package')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1"
            :to="{
              name: 'update-freefire-sg-package',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t('button.update') }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view :fields="fields" v-model="data" :initValue="initData">
      </n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t('button.back') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonDelete from '@/components/NButtonDelete'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInputView from '@/components/NInputView'
import formInput from './formInput'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import NTimeInput from '@/components/NTimeInput.vue'

const PackageRepository = Repository.get('package')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BFormTextarea,
    NTimeInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      disable: true,
      initData: {},
      loading: false,
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      PackageRepository.show(this.$route.params.id)
        .then((response) => {
          if (response?.data?.data) {
            this.initData = {
              ...response.data.data,
            }
            this.data = {
              ...response.data.data,
            }
          }
        })
        .catch()
        .then(() => {})
    },
    deleteItem() {
      PackageRepository.delete(this.$route.params.id)
        .then((response) => {
          this.back()
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...formInput]
    return { fields }
  },
}
</script>
